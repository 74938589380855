import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated GraphQL Developers | Offshore GraphQL Development Company in India',
    metaDescription: 'Outsource GraphQL Developer & programmers from India to develop secure APIs at the lowest possible cost. Hire now!',
    h1: 'Hire Dedicated GraphQL Developers',
    h1Subtext1: 'Build secure back-end APIs to support multiple platforms with MatteCurve.',
    h1Subtext2: 'MatteCurve is the best GraphQL development company in India. We are a JavaScript company and have dedicated GraphQL developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire GraphQL developers within 7 days.',
    aboutSection: {
        title: 'About GraphQL',
        subTitle: 'An API query language to write efficient, powerful and flexible APIs.',
        paragraph1: "GraphQL is the best alternative to REST. At its core, GraphQL enables declarative data fetching where a client can specify exactly what data it needs from an API. Instead of multiple endpoints that return fixed data structures, a GraphQL server only exposes a single endpoint and responds with precisely the data a client asked for.",
        paragraph2: `
        GraphQL allows us to fetching data with queries, write data with mutations and realtime updates with subscriptions. 
        Fragments are a handy feature to help to improve the structure and reusability of your GraphQL code. A fragment is a collection of fields on a specific type.
        Alias can be added to the queries.
        `,
        paragraph3: `
        Like REST, GraphQL is not specific to a technology or language. A GraphQL developer should be good experienced in modeling data, security, authentication and authorization, error handling etc.
        GraphQL Playground is an important tool in the development of GraphQL APIs, one must know how to use it.
        Hire GraphQL developers from MatteCurve who are experienced and skilled in developing large scale GraphQL applications. 
        `,
        keyPoints: [
            "GraphQL is a Query Language for APIs",
            "GraphQL replaces REST as an API design paradigm and is becoming the new standard for exposing the data and functionality of a web server.",
            "GraphQL is not only for React Developers.",
            "GraphQL has its own type system that’s used to define the schema of an API. The syntax for writing schemas is called Schema Definition Language (SDL).",
            "GraphQL supports fetching data with complex queries. It does support loading multiple resources all together.",
            "GraphQL support creating new data, updating existing data and deleting existing data with mutations.",
            "GraphQL supports real-time updates and subscriptions.",
            "GraphQL resolver is a function which is used to resolve a property of GraphQL Schema.",
            "Implementing server-side caching is a challenge on GraphQL."
        ],
    },
    statsSection: {
        paragraph: "GraphQL is gaining a huge market day by day due to its user-friendly API Script Language. Here are some GraphQL Application Programming Interfaces (APIs) examples. Companies like GitHub, Airbnb, Shopify, Expedia, Trulia and many other companies used these techniques. Hire Dedicated GraphQL Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated GraphQL Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated GraphQL Developers?",
        "Hire dedicated GraphQL Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for GraphQL Development?",
        paragraph: "MatteCurve has been recognized as one of the leading web development companies in building high-performance GraphQL apps from India. MatteCurve offers you deep expertise in GraphQL web development and GraphQL app development, delivered by a team of highly-skilled GraphQL Developers with unmatched expertise in building progressive and dynamic web apps using GraphQL.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
